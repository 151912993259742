import React, { useState, useEffect } from 'react';
import './styles/mobile-tabs.css';
import { publicRequest } from '../requestMethod';
import { useLocation } from 'react-router-dom';

const MobileTabs = () => {
  const [activeTab, setActiveTab] = useState('');
  const [product, setProduct] = useState({});
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await publicRequest.get('/api/products/find/' + id)
        const productData = res.data;
        setProduct(productData);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    getProduct();
  }, [id]);

  const changeTab = (tab) => {
    setActiveTab(activeTab === tab ? '' : tab);
  };

  return (
    <div className="accordion-container">
      {/* Description Tab */}
      <div className="accordion-item">
        <button
          className={`accordion-button ${activeTab === 'description' ? 'active' : ''}`}
          onClick={() => changeTab('description')}
        >
          Description
          <span className="accordion-icon">{activeTab === 'description' ? '−' : '+'}</span>
        </button>
        {activeTab === 'description' && (
          <div className="accordion-content">
            <h4>{product.artist}</h4>
            <p>
              {product.title} was shot by <i>{product.artist}</i>, a talented self-taught photographer, retoucher, artist and founder of <i>Framed by Create</i> currently based in Philadelphia, PA. <i>{product.artist}</i> focuses on POV framing and 
              thought provoking imagery. He shoots mostly travel, city and nature photography. 
            </p>
            <p>
              <strong>Printing:</strong> Each museum-quality Giclee Fine Art Print is part of our Premier collection and
              printed in the USA on heavy matte, artist-grade, acid-free 300gsm photo paper using archival inks.
            </p>
          </div>
        )}
      </div>

      {/* Printing & Framing Tab */}
      <div className="accordion-item">
        <button
          className={`accordion-button ${activeTab === 'printing' ? 'active' : ''}`}
          onClick={() => changeTab('printing')}
        >
          Printing & Framing
          <span className="accordion-icon">{activeTab === 'printing' ? '−' : '+'}</span>
        </button>
        {activeTab === 'printing' && (
          <div className="accordion-content">
            <h4>Printing & Framing</h4>
            <p>
              Our premium hardwood frames are handcrafted with UV-blocking acrylic glass, available in black, white, and
              natural wood.
            </p>
            <p>
              <strong>Classic Frames:</strong> Our Small, Medium, and Large prints come framed with a 2.5” mat board.
            </p>
            <p>
              <strong>Shadow Boxes:</strong> Available for Giant and Collector-sized prints, adding extra depth and a
              definitive look.
            </p>
          </div>
        )}
      </div>

      {/* Shipping & Returns Tab */}
      <div className="accordion-item">
        <button
          className={`accordion-button ${activeTab === 'shipping' ? 'active' : ''}`}
          onClick={() => changeTab('shipping')}
        >
          Shipping & Returns
          <span className="accordion-icon">{activeTab === 'shipping' ? '−' : '+'}</span>
        </button>
        {activeTab === 'shipping' && (
          <div className="accordion-content">
            <h4>Shipping & Returns</h4>
            <p>
              <strong>Shipping:</strong> Each piece takes 2-3 weeks for printing, mounting, framing, and shipping, with
              tracking provided.
            </p>
            <p>
              <strong>Returns:</strong> Custom prints are non-returnable, but contact us if your artwork arrives damaged
              for repair or replacement options.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileTabs;