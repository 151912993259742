import React from 'react'
import './styles/category-menu.css'
import { MdArrowBack } from 'react-icons/md';


const CategoryMenu = ({handleBack}) => {
  return (
    <div className="category-navigation-overlay">
      <form action="" className='cat-nav-back'>
        <button className="back-button" onClick={() => handleBack()}>
          <MdArrowBack size="24px" color="black" /> 
        </button> 
        <span>CATEGORIES</span>
      </form>
      
      <ul className="category-navigation-links">
        <li><a href="/shop/all-prints?category=black+%26+white" onClick={handleBack}>BLACK & WHITE</a></li>
        <li><a href="/shop/all-prints?category=travel" onClick={handleBack}>TRAVEL</a></li>
        <li><a href="/shop/all-prints?category=nature" onClick={handleBack}>NATURE</a></li>
        <li><a href="/shop/all-prints?category=city" onClick={handleBack}>CITY</a></li>
      </ul>
      
    </div>
  )
}

export default CategoryMenu