import React, {createContext, useContext, useState} from 'react'

const FrameContext = createContext();
export const useFrame = () => useContext(FrameContext);

const FrameProvider = ({children}) => {
  const [frame, setFrame] = useState('black');
  return (
    <FrameContext.Provider value={{frame, setFrame}}>
      {children}
    </FrameContext.Provider>
  )
}

export default FrameProvider