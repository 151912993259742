import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    products:[],
    quantity: 0,
    total: 0 
  },
  reducers: {
    addProduct: (state, action) => {
      const { 
        product, quantity, size, frameColor, frameColorName,
        border, price, memberPrice
      } = action.payload;
      state.products.push({
        ...product,
        quantity,
        size,
        frameColor,
        frameColorName,
        border,
        price,
        memberPrice
      });
      state.quantity += quantity;
      state.total += (state.isMember ? memberPrice : price) * quantity;
    },
    removeProduct: (state, action) => {
      const removedItem = state.products[action.payload];
      state.quantity -= removedItem.quantity;
      state.total -= removedItem.price * removedItem.quantity;
      state.products.splice(action.payload, 1);
      
    },
    updateTotal: (state, action) => {
      if (state.products.length === 0) {
        state.total = 0;
        state.savings = 0;
        return;
      }
      let regularTotal = 0;
      let memberTotal = 0;

      state.products.forEach(product => {
        regularTotal += product.price * product.quantity;
        memberTotal += product.memberPrice * product.quantity;
      });

      state.regularTotal = regularTotal;
      state.memberTotal = memberTotal;      
      state.savings = regularTotal - memberTotal;
    }
  }
});

export const {addProduct, removeProduct, updateTotal} = cartSlice.actions;
export default cartSlice.reducer;