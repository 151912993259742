import React from 'react'
import "./styles/product-item.css"
import { FavoriteBorderOutlined } from '@mui/icons-material'

const ProductItem = ({item, frame}) => {
  console.log(frame)
  return (
    <div className='this-product-item vertical' data-product-name={item.pname} 
        data-product-slug={item._id} data-product-artist={item.artist} data-position='' style={{order: 1}}>
      
      <div className='product-item-images'>
        <a className='product-item-image-holder loaded' href={`/product/${item._id}`} data-type='page-transition'>
        <div className=' p-center lazyloaded' data-frame={frame} data-frame-orientation=''>
            {frame && <img className='frame-overlay' src={frame} alt="Frame" />}
            <span className='object-fit'>
              <img className='product-item-image product-item-image-first-image' src={item.img} alt={item.title}
                  width='{}' height=''
              />
            </span>
            
          </div>
        </a>
      </div>
      
      <div className='product-item-info'>
        <div className='product-item-title-container'>
          <a className='product-item-title' href={`/product/${item._id}`}>{item.title}</a>
          <div className='product-item-artist tw-text-left'>
            <a href={`/artist/${item.artist}`}>{item.artist}</a>
          </div>
        </div>
        <div className='product-item-save'>
          <FavoriteBorderOutlined style={{ fontSize: '20px' }}/>
        </div>
      </div>
    </div>
  )
}

export default ProductItem