import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../components/Navbar'
import Announcement from '../components/Announcement'
import Footer from '../components/Footer'
import "./styles/product-list.css"
import ProductItem from '../components/ProductItem'
import { useLocation, useNavigate } from 'react-router-dom'
import Products from '../components/Products'
import { useNavbarContext } from '../components/NavbarContext'
import {useFrame} from '../components/FrameContext'
import { useDispatch } from 'react-redux'
import { addProduct } from '../redux/cartRedux'
import wood from '../assets/wood.jpeg'
import MobileMenu from '../components/MobileMenu'
import CategoryMenu from '../components/CategoryMenu'

const ProductList = () => {
  const {showNav} = useNavbarContext();
  const frameColor = {
    black: 'https://firebasestorage.googleapis.com/v0/b/print-shop-635e7.appspot.com/o/frame-black.png?alt=media&token=f4fc678d-ef65-45e4-b2ef-aaeeae29325f',
    white: 'https://firebasestorage.googleapis.com/v0/b/print-shop-635e7.appspot.com/o/frame-white.png?alt=media&token=d58c13b6-f11f-4de6-960c-7207ec9552d9',
    wood: 'https://firebasestorage.googleapis.com/v0/b/print-shop-635e7.appspot.com/o/frame-wood.png?alt=media&token=8fe4bb2b-8e1d-4d15-a85f-4da17ed1e94c'
  }
  const filterMappings = {
    "All": "all",
    "Black & White": "black-white", // Display name maps to the database query value
    "Travel": "travel",
    "Nature": "nature",
    "City": "city"
  };

  const orientationMappings = {
    "All": "all",
    "Portrait": "portrait",
    "Landscape": "landscape"
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const cat = searchParams.get('category') || null;
  const ori = searchParams.get('orientation') || null; 
  const [filter, setfilters] = useState({});
  const [frame, setFrame] = useState(frameColor.black);
  const [catDropdownOpen, setCatDropdownOpen] = useState(false);
  const [oriDropdownOpen, setOriDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedOrientation, setSelectedOrientation] = useState('All');
  const catDropdownRef = useRef(null);
  const oriDropdownRef = useRef(null);  
  const navigate = useNavigate(); 
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
    setIsCategoriesOpen(false);
  };

  const handleCategoriesClick = () => {
    setIsCategoriesOpen(true);
  };

  const handleBack = () => {
    setIsCategoriesOpen(false);
  };



  useEffect(() => {
    const handleClickOutside = (e) => {
      if (catDropdownRef.current && !catDropdownRef.current.contains(e.target)) {
        setCatDropdownOpen(false)
      }
      if (oriDropdownRef.current && !oriDropdownRef.current.contains(e.target)) {
        setOriDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const categoryFromUrl = searchParams.get('category');
    const orientationFromUrl = searchParams.get('orientation');
  
    const findDisplayValue = (mapping, value) => Object.keys(mapping).find(key => mapping[key] === value) || 'All';
  
    const initialCategory = categoryFromUrl ? findDisplayValue(filterMappings, categoryFromUrl) : 'All';
    const initialOrientation = orientationFromUrl ? findDisplayValue(orientationMappings, orientationFromUrl) : 'All';
  
    setSelectedCategory(initialCategory);
    setSelectedOrientation(initialOrientation);
  }, [location.search]);


  const handleFilters = (filter, value) => {
    const isCatFilter = filter === 'category';
  
    if (isCatFilter) {
      setSelectedCategory(value);
    } else {
      setSelectedOrientation(value);
    };
    
    setCatDropdownOpen(false);
    setOriDropdownOpen(false);   
    
    let urlParams = new URLSearchParams();

    if (isCatFilter) {
      if (value.toLowerCase() !== 'all') {
        urlParams.set('category', filterMappings[value.toLowerCase()] || value.toLowerCase());
      }
    } else {
      if (value.toLowerCase() !== 'all') {
        urlParams.set('orientation', orientationMappings[value.toLowerCase()] || value.toLowerCase());
      }
    }
    
    if (isCatFilter && selectedOrientation && selectedOrientation.toLowerCase() !== 'all') {
      urlParams.set('orientation', orientationMappings[selectedOrientation.toLowerCase()] || selectedOrientation.toLowerCase());
    } else if (!isCatFilter && selectedCategory && selectedCategory.toLowerCase() !== 'all') {
      urlParams.set('category', filterMappings[selectedCategory.toLowerCase()] || selectedCategory.toLowerCase());
    }

    const newPath = `/shop/all-prints${urlParams.toString() ? '?' + urlParams.toString() : '/'}`;
    navigate(newPath);   
  };

  const toggleDropdown = (type) => {
    if (type === 'category') {
      setCatDropdownOpen(!catDropdownOpen);
    } else if (type === 'orientation') {
      setOriDropdownOpen(!oriDropdownOpen);
    }
  };

  const handleFrame = (e) => {
    const newFrame = frameColor[e.target.value.toLowerCase()];
    console.log('New Frame selected:', newFrame);
    setFrame(newFrame);
  };

  const dispatch = useDispatch();
  const addToCart = (product) => {
    dispatch(addProduct({ product, frame }));
  };

  return (
    <div className='product-list-container'>  
      <>
        <Navbar handleMenuToggle={handleMenuToggle} />
        {isOpen && (
          <MobileMenu
            isOpen={isOpen}
            handleMenuToggle={handleMenuToggle}
            handleCategoriesClick={handleCategoriesClick}
          />
        )}
        {isCategoriesOpen && <CategoryMenu handleBack={handleBack} />}
      </>
      <Announcement /> 
      <div className='product-list-header-wrapper'>
        <h1 className='product-list-header-title'>All Prints</h1>
        <p className='product-list-header-description'>
        Every print is produced on premium artist-grade matte paper, renowned for high-quality color and gallery-standard presentation.
        </p>
      </div>
      
      <div className={`product-collection-container ${showNav ? 'navbar-visible' : ''}`}>
        <div className={`product-list-page-filter-container wrapper-full-width ${showNav ? 'navbar-visible-b' : ''}`}>
          <div className='product-list-filter-container'>
            <div className='product-list-filter-category product-filter-container modern-dropdown' >
              <div className='product-list-filter-label'>Category</div>
                <div className='product-list-dropdown-container' onChange={handleFrame} ref={catDropdownRef} onClick={() => toggleDropdown('category')}>
                  <div className='dropdown-select-items select-with-scroll-bar'>
                    <div className='selected-value'>{selectedCategory}</div>
                    <div className={`arrow ${catDropdownOpen ? 'up' : 'down'}`}></div>
                  </div> 
                  {catDropdownOpen && (
                  <div className='dropdown-list modern-list'>
                    {["All", "Black & White", "Travel", "Nature", "City"].map(category => (
                      <div key={category} className='dropdown-list-text' onClick={() => handleFilters("category", category)}>{category}</div>
                    ))}
                  </div>
                  )}
                </div>
              </div>
              <div className='product-list-filter-orientation product-filter-container'>
                <div className='product-list-filter-label'>Orientation</div>
                <div className='product-list-dropdown-container' ref={oriDropdownRef}>
                <div className='dropdown-select-items select-with-scroll-bar select-hide' onClick={() => toggleDropdown('orientation')}>
                  <div className='selected-value'>{selectedOrientation}</div>
                  <div className={`arrow ${oriDropdownOpen ? 'up' : 'down'}`}></div>
                </div>
                {oriDropdownOpen && (
                  <div className='dropdown-list modern-list'>
                    {["All","Portrait", "Landscape"].map(ori => (
                      <div key={ori} className='dropdown-list-text' onClick={() => handleFilters("orientation", ori)}>{ori}</div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='product-list-filter-container show-on-tablet-up-only'>
            <div className='product-list-filter-frame-selector-container' onChange={handleFrame}>
              <div className='product-list-filter-label'>
                Frame Preference {""}
                <span className='product-list-frame-selector-color-label' >Black</span>
              </div>
              <div className='product-list-frame-options-container'>
                <div className='product-list-frame-option-selector'>
                  <input id='frame-selector-Black' name='frame' type="radio" value={'Black'} className='frame-selector-input'/>
                  <label htmlFor="frame-selector-Black" className='frame-selector-swatch-label'>
                    <div className='frame-selector-swatch black' style={{backgroundColor: 'black'}}></div>
                  </label>
                </div>
                <div className='product-list-frame-option-selector'>
                  <input id='frame-selector-White' name='frame' type="radio" value={'White'} className='frame-selector-input'/>
                  <label htmlFor="frame-selector-White" className='frame-selector-swatch-label'>
                    <div className='frame-selector-swatch white' style={{backgroundColor: 'white'}}></div>
                  </label>
                </div>
                <div className='product-list-frame-option-selector'>
                <input id='frame-selector-Wood' name='frame' type="radio" value={'Wood'} className='frame-selector-input'/>
                  <label htmlFor="frame-selector-Wood" className='frame-selector-swatch-label'>
                    <div className='frame-selector-swatch wood' style={{backgroundImage: `url(${'https://images.pexels.com/photos/129728/pexels-photo-129728.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'})`}}></div>
                  </label>
                </div>
                <div className='product-list-frame-option-selector'>
                <input id='frame-selector-NoFrame' name='frame' type="radio" value={'No Frame'} className='frame-selector-input'/>
                  <label htmlFor="frame-selector-NoFrame" className='frame-selector-swatch-label'>
                    <div className='frame-selector-swatch no-frame'></div>
                  </label>
                </div>
              </div>
            </div>
            <script></script>
          </div>
          <div className='product-list-filter-container show-on-tablet-down-only'></div>
        </div>
        <Products cat={cat} filter={filter} frame={frame} orientation={ori}/>
      </div>
      <Footer />
    </div>
  )
}

export default ProductList