import React, { useEffect, useState } from 'react'
import NewArrItems from './NewArrItems';
import axios from 'axios';
import './styles/newArr.css'

const NewArrivals = ({frame}) => {
  const [products, setProducts] = useState([]);
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";


  useEffect(() => {
    const getProducts = async () => {
      try {
        let queryString = '';
        const res = await axios.get(`${BASE_URL}/api/shop/all-prints${queryString}`);
        if (Array.isArray(res.data)) {
          const sortedItems = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setProducts(sortedItems);
        } else {
          console.error("Expected an array, got:", res.data);

        }
        console.log("API Response:", res.data);
      } catch (err) {
        console.log(err)   
      } 
    }
    
    getProducts();
    
  },[]);

  return (
    <div className='containerNewArr'>
      <div className='featured-products-container'>
        <div className='featured-products-title'>Latest Releases</div>
        <div className='featured-products-product-container'>
          <div className='new-arr-slick-list'>
            <div className='new-arr-slick-track'>
              {products.slice(0, 3).map(item=> (
                <NewArrItems item={item} key={item._id} frame={frame}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewArrivals