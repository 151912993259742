import React from 'react';
import { useLocation } from 'react-router-dom';
import { userRequest } from '../requestMethod';
import { UseSelector, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';



const Success = () => {
  const location = useLocation();
  const data = location.state.stripeData;
  const cart = location.state.cart;
  const currentUser = useSelector((state) => state.user.currentUser);
  const [orderID, setOrderID] = useState(null);


  useEffect (() => {
    const createOrder = async () => {
      
      if (!data || !currentUser) {
        console.log("Data or currentUser is unavailable");
        return;
      }  

      try {
        const res = await userRequest.post('/orders', {
          // userId: currentUser._id,
          products: cart.products.map((item) => ({
            productId: item._id,
            quantity: item._quantity
          })),
          amount: cart.total,
          address: data.billing_details.address
        });
        console.log(res)
        // setOrderID(res.data._id);
      } catch (err) {
        console.error("Error creating order: ", err);
      } 
    };
    createOrder();
  }, [cart, data, currentUser]);

  console.log(data)


  if (!data || !cart) {
    return <div>Error: No data available</div>;
  }

  return (
    <div>
      {orderID 
      ? `Order has been confirmed. Your order number is ${orderID}`
      : `Confirmed. Your order is being processed!`
      }
      <button style={{padding: 10, marginTop: 20}}>Continue Shopping</button>
    </div>
  );
};

export default Success