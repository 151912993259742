import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Announcement from '../components/Announcement';
import Slider from '../components/Slider';
import NewArrivals from '../components/NewArrivals';
import Footer from '../components/Footer';
import Products from '../components/Products';
import MobileMenu from '../components/MobileMenu';
import CategoryMenu from '../components/CategoryMenu';
import '../components/styles/mobile-menu.css'

const Home = () => {
  const frameColor = {
    black: 'https://firebasestorage.googleapis.com/v0/b/print-shop-635e7.appspot.com/o/frame-black.png?alt=media&token=6f2c3371-eeb8-4d81-8706-c016533fbb91',
  }

  const [frame, setFrame] = useState(frameColor.black); 
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
    setIsCategoriesOpen(false);
  };

  const handleCategoriesClick = () => {
    setIsCategoriesOpen(true);
  };

  const handleBack = () => {
    setIsCategoriesOpen(false);
  };


  return (
    <div className='main-container'>
      <>
        <Navbar handleMenuToggle={handleMenuToggle} />
        {isOpen && (
          <MobileMenu
            isOpen={isOpen}
            handleMenuToggle={handleMenuToggle}
            handleCategoriesClick={handleCategoriesClick}
          />
        )}
        {isCategoriesOpen && <CategoryMenu handleBack={handleBack} />}
      </>
      <Announcement />
      <Slider />
      <NewArrivals frame={frame} />
      <Footer />
    </div>
  )
}

export default Home