import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL
const getToken = () => {
  try {
    const persistedRoot = localStorage.getItem("persist:root");
    if (persistedRoot) {
      const rootObj = JSON.parse(persistedRoot);
      const user = JSON.parse(rootObj.user);
      const token = user?.currentUser?.accessToken;
      if (token) {
        console.log(token); // Logging the token for debug purposes
        return token;
      }
    }
  } catch (error) {
    console.error("Error parsing JSON data from localStorage:", error);
  }

  return '';
};

export const publicRequest = axios.create({
  baseURL: BASE_URL
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {token: `Bearer ${getToken()}`}
});
