import React from 'react'
import './styles/newArrItems.css'
import { FavoriteBorderOutlined, SearchOutlined, ShoppingCartOutlined } from '@mui/icons-material'
import styled from 'styled-components'
import { Link } from 'react-router-dom';

const Info = styled.div`
  opacity: 1;
  height: 10%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;

`;
const Container = styled.div`
  display: flex;
  flex: 1 1 100%; /* Full width on mobile by default */
  flex-direction: row;
  margin: 10px;
  height: auto; /* Let the height adjust to content */
  overflow: hidden;

  @media (min-width: 600px) {
    flex: 0 0 calc(33.333% - 20px); /* Adjust width for larger screens */
    height: 900px; /* Original height retained on desktop */
  } 

  &:hover ${Info} {
    opacity: 1;
  }
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: all 0.5s;


  &:hover{
    background-color: aliceblue;
    transform: scale(1.1);
  }

  @media (min-width: 600px) {
    width: 40px;
    height: 40px;
  }
`;

const NewArrItems = ({item, frame}) => {
  return (
    <Container >
        <div className='featured-product-carousel-item' >
          <div className='featured-product-item-container '>
            <div className='product-item-images'>
              <a className='product-item-image-holder loaded' href={`/product/${item._id}`} data-type='page-transition'>
              <div className=' p-center lazyloaded' data-frame={frame} data-frame-orientation=''>
                  {frame && <img className='frame-overlay' src={frame} alt="Frame" />}
                  <span className='object-fit'>
                    <img className='product-item-image product-item-image-first-image' src={item.img} alt={item.title}
                        width='{}' height=''
                    />
                  </span>
                </div>
              </a>
            </div>
            <div className='info-NewArrItems'>
              <div className='info-NewArrItems-container'>
                <h1 className='title-NewArrItems'>
                  <a className='newArr-item-title' href={`/product/${item._id}`}>{item.title}</a>
                </h1>
                <div className='artist-NewArrItems tw-text-sm !tw-text-left !tw-leading-none'>
                  <a href={`/artist/${item.artist}`}>{item.artist}</a>
                </div>
              </div>
            </div>
            <Info>
                <Icon>
                  <Link to={`/product/${item._id}`}>
                    <ShoppingCartOutlined style={{ fontSize: '20px' }}/>
                  </Link>
                </Icon>
                <Icon>
                  <FavoriteBorderOutlined style={{ fontSize: '20px' }}/>
                </Icon>
              </Info>
          </div>
        </div>
    </Container>
  )
}

export default NewArrItems 