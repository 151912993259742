import React from 'react'
import './styles/announcement.css';


const Announcement = () => {
  return (
    <div className='container'>Announcement</div>
  )
}

export default Announcement