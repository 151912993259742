import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Announcement from '../components/Announcement';
import './styles/product.css';
import Star from '../components/Star';
import Footer from '../components/Footer';
import { useLocation } from 'react-router-dom';
import { publicRequest } from '../requestMethod';
import { addProduct } from '../redux/cartRedux';
import { useDispatch } from 'react-redux';
import { FaAngleRight, FaRegHeart} from 'react-icons/fa';
import { GoQuestion } from "react-icons/go";
import { IoLocationOutline } from "react-icons/io5";
import CarouselItem from '../components/CarouselItem';
import MobileTabs from '../components/MobileTabs';
import MobileMenu from '../components/MobileMenu';
import CategoryMenu from '../components/CategoryMenu';

const Product = ({frame}) => {
  
  const sizesData = {
    small: { dimensions: '14.5 x 18.5 in', price: 200, memberPrice: 160 },
    medium: { dimensions: '16 x 20 in', price: 375, memberPrice: 305 },
    large: { dimensions: '18 x 24 in', price: 565, memberPrice: 460 },
    giant: { dimensions: '24 x 36 in', price: 1025, memberPrice: 825 },
    collector: { dimensions: '36 x 48 in', price: 1950, memberPrice: 1625 },
    exhibition: { dimensions: '48 x 60 in', price: 3425, memberPrice: 2825 },
  };

  const frameColors = {
    black: 'https://firebasestorage.googleapis.com/v0/b/print-shop-635e7.appspot.com/o/frame-black.png?alt=media&token=4852ee1a-f4ea-4998-8f48-9772a2ac33ce',
    white: 'https://firebasestorage.googleapis.com/v0/b/print-shop-635e7.appspot.com/o/frame-white.png?alt=media&token=30714644-039e-4c09-8159-d647a1cc8d82',
    wood: 'https://firebasestorage.googleapis.com/v0/b/print-shop-635e7.appspot.com/o/frame-wood.png?alt=media&token=8fcfda58-4948-433b-9816-a8dd55bfc8dd'
  }

  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [size, setSize] = useState('small');
  const [frameColor, setFrameColor] = useState(frameColors.black);
  const [frameColorName, setFrameColorName] = useState('Black');
  const [border, setBorder] = useState('Border');
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [imageSrc, setImageSrc] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 603);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
    setIsCategoriesOpen(false);
  };

  const handleCategoriesClick = () => {
    setIsCategoriesOpen(true);
  };

  const handleBack = () => {
    setIsCategoriesOpen(false);
  };


  
  const handleSizeChange = (e) => {
    const selectedSize = e.target.value;
    setSize(selectedSize);
    
    const pricing = sizesData[selectedSize];
    setProduct(prevState => ({
      ...prevState,
      price: pricing.price,
      memberPrice: pricing.memberPrice
    }));
  };

  const handleFrameChange = (e) => {
    const frameValue = e.target.value;
    if (frameValue) {
      setFrameColor(frameColors[frameValue.toLowerCase()]);
      setFrameColorName(frameValue.charAt(0).toUpperCase() + frameValue.slice(1));
    }
  };
  
  const handleMountChange = (e) => {
    const selectedMount = e.target.value;
    setBorder(selectedMount);
    setImageSrc(selectedMount === 'Full Bleed' ? product.fullBleed : product.img); 
  };

  const handleClick = () => {
    dispatch(
      addProduct({
        product, 
        quantity: 1, 
        size, 
        frameColor, 
        frameColorName, 
        border,
        price: product.price,
        memberPrice: product.memberPrice
      })
    )
    setShowToast(true);
  } 

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 603);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await publicRequest.get('/api/products/find/' + id)
        const productData = res.data;
        const defaultPricing = sizesData['small'];
        console.log(productData)

        setProduct({
          ...productData,
          price: defaultPricing.price,
          memberPrice: defaultPricing.memberPrice,
        });
        setImageSrc(productData.img);
        
        if (res.data.sizes && res.data.sizes.length > 0) {
          setSize(res.data.sizes[0]);
        }
        if (res.data.frames && res.data.frames.length > 0) {
          setFrameColor(res.data.frames[0]);
        }
        if (res.data.borders && res.data.borders.length > 0) {
          setBorder(res.data.borders[0]);
        }
      } catch (err) {}
    }
    getProduct()
  },[id]);

  useEffect(() => {
    let timer1, timer2;
    if (showToast) {
      timer1 = setTimeout(() => {
        setShowToast(false);
      }, 5000);

      timer2 = setTimeout(() => {
        document.querySelector('.progress').classList.remove('active');
      }, 5300);
    }

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [showToast]);

  const closeToast = () => {
    setShowToast(false);
  };

    
  const [activeTab, setActiveTab] = useState(
    () => (window.matchMedia('(min-width: 821px)').matches ? 'description' : '')
  );

  
  const changeTab = (tab) => {
    if (activeTab === tab) {
      setActiveTab('');
    } else {
      setActiveTab(tab);
    }
  };
  
  return (
    <div className='product-main-container'>
      <>
        <Navbar handleMenuToggle={handleMenuToggle} />
        {isOpen && (
          <MobileMenu
            isOpen={isOpen}
            handleMenuToggle={handleMenuToggle}
            handleCategoriesClick={handleCategoriesClick}
          />
        )}
        {isCategoriesOpen && <CategoryMenu handleBack={handleBack} />}
      </>
      <Announcement />
      <div className='product-buying-section'>
      {!isMobile && (
          <div className='product-breadcrumbs-container-main'>
            <div className='product-breadcrumb-container'>
              <a href="/shop/all-prints/">Prints</a>
              &nbsp;&nbsp;<FaAngleRight />
            </div>
            <div className='product-breadcrumb-container'>
              <a href="#">{product.artist}</a>
              &nbsp;&nbsp;<FaAngleRight/>
            </div>
            <div className='product-breadcrumb-container'>
              <div className='product-breadcrumb'>{product.title}</div>
            </div>
          </div>
        )}
        
        <div className='product-page-container wrapper-full-width'>
          <div className='product-page-main-product-section'>
            {/* <div className='show-mobile-only'></div> */}
            <div className='show-mobile-only product-header'>
              <div className='product-title t-h-1'>{product.title}</div>
              <div className='artist-location-container'>
                <div className='artist-location'>
                  <span><IoLocationOutline />&nbsp;</span>
                  <div className='artist-location-text'>
                    {product.location}
                  </div>
                </div>
                <div className='divider'>|</div>
                <i>
                  <a className='artist-link' href="artist">{product.artist}</a>
                </i>
              </div>
            </div>
            <div className='product-section-image-viewer-container'>
              <div className='product-section-images-container loaded'>
                <div className='product-item-images-final'>
                    <div className=' p-center lazyloaded' data-frame={frame} data-frame-orientation=''>
                      {frameColor && <img className='frame-overlay-final' src={frameColor} alt="Frame" />}
                      <span className='object-fit'>
                        <img className='product-item-image product-item-image-first-image' src={imageSrc} alt={product.title}
                            width='{}' height=''
                        />
                      </span>
                    </div>
                </div>
                <div className='product-section-save'>
                  <div className='product-section-save-button-container'>
                    <button className='product-section-save-button  group-hover:tw-opacity-80 '>
                      <div></div>
                      <div className='product-section-save-button-text'>
                        <a href="">
                          <FaRegHeart />                         
                          <span className='save-button-text-save'>Save</span>
                          <span className='save-button-text-saved'>Saved</span>
                        </a>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            <div className='product-section-form-container'>

              <div className='main-product--desktop-only'>
                <div className='main-product-top'>
                  <div className='product-title t-h-1'>{product.title}</div>
                  <div className='artist-location-container'>
                    <div className='artist-location'>
                      <span><IoLocationOutline />&nbsp;</span>
                      <div className='artist-location-text'>
                        {product.location}
                      </div>
                    </div>
                    <div className='divider'>|</div>
                    <i>
                      <a className='artist-link' href="artist">{product.artist}</a>
                    </i>
                  </div>
                </div>
              </div>
              
              <div className='main-product-buying-panel--pricing'>
                <div className='non-member-price'> 
                  <span style={{padding: 0, display: 'inline'}}>${product.price}</span>
                  &nbsp;REGULAR&nbsp;
                </div>
                <div className='member-price'>
                  <span style={{padding: 0, display: 'inline'}}>${product.memberPrice}</span>
                  &nbsp;member&nbsp;
                  <a href="/register">
                    <GoQuestion />                
                  </a>
                </div>
                <div className='product-shipping'>
                  <p>Shipping: Free for a limited time</p>
                </div>
                <klarna-placement data-key="credit-promotion-badge" data-locale="en-US" data-purchase-amount="12000">
                  klarna code?
                </klarna-placement>
              </div>
              <div className='main-product-size-selector-container'>
                <div className='main-product-section-size-selector-title'>
                  <div className='main-product-section-size-selector-title-header'>
                    <div className='main-product-section-size-selector-final-dimensions'>
                      <h3 className='main-product-section-size-selector-label'>Choose Your Size</h3>
                      <span className='size-selector-measurement size-selector-measurement-alt'>{size.charAt(0).toUpperCase() + size.slice(1)}</span>
                    </div>
                  </div>
                </div>
                <div className='main-product-section-size-selectors-container desktop-only'>

                  <input type="radio" id='size-selector-small' className={`main-product-section-size-selector`}
                    name='size' value={'small'} onChange={handleSizeChange} defaultChecked/>
                  <label htmlFor="size-selector-small" className='main-product-section-size-selector'>
                    <div className='main-product-section-size-selector-name'>Small</div>
                    <div className='main-product-section-size-selector-dimensions'>14.5 x 18.5 in</div>
                    <div className='main-product-section-size-selector-price'>
                      <span className='reset-money' style={{padding: 0, display: 'inline'}}>$160</span>
                    </div>
                  </label>

                  <input type="radio" name="size" id="size-selector-medium" className='main-product-section-size-selector-input' 
                    value={'medium'} onChange={handleSizeChange}/>
                  <label htmlFor="size-selector-medium" className='main-product-section-size-selector'>
                    <div className='main-product-section-size-selector-name'>Medium</div>
                    <div className='main-product-section-size-selector-dimensions'>19.5 x 26 in</div>
                    <div className='main-product-section-size-selector-price'>
                      <span className='reset-money' style={{padding: 0, display: 'inline'}}>$305</span>
                    </div>
                  </label>

                  <input type="radio" name="size" id="size-selector-large" className='main-product-section-size-selector-input' 
                    value={'large'} onChange={handleSizeChange}/>
                  <label htmlFor="size-selector-large" className='main-product-section-size-selector'>
                    <div className='main-product-section-size-selector-name'>Large</div>
                    <div className='main-product-section-size-selector-dimensions'>24.5 x 33.5 in</div>
                    <div className='main-product-section-size-selector-price'>
                      <span className='reset-money' style={{padding: 0, display: 'inline'}}>$460</span>
                    </div>
                  </label>

                  <input type="radio" name='size' id='size-selector-giant' className='main-product-section-size-selector-input' 
                    value={'giant'} onChange={handleSizeChange}/>
                  <label htmlFor="size-selector-giant" className='main-product-section-size-selector'>
                    <div className='main-product-section-size-selector-name'>Giant</div>
                    <div className='main-product-section-size-selector-dimensions'>31.5 x 44 in</div>
                    <div className='main-product-section-size-selector-price'>
                      <span className='reset-money' style={{padding: 0, display: 'inline'}}>$825</span>
                    </div>
                  </label>

                  <input type="radio" name='size' id='size-selector-collector' className='main-product-section-size-selector-input' 
                    value={'collector'} onChange={handleSizeChange}/>
                  <label htmlFor="size-selector-collector" className='main-product-section-size-selector'>
                    <div className='main-product-section-size-selector-name'>Collector</div>
                    <div className='main-product-section-size-selector-dimensions'>39.5 x 56 in</div>
                    <div className='main-product-section-size-selector-price'>
                      <span className='reset-money' style={{padding: 0, display: 'inline'}}>$1625</span>
                    </div>
                  </label>

                  <input type="radio" name='size' id='size-selector-exhibition' className='main-product-section-size-selector-input' 
                    value={'exhibition'} onChange={handleSizeChange}/>
                  <label htmlFor="size-selector-exhibition" className='main-product-section-size-selector'>
                    <div className='main-product-section-size-selector-name'>Exhibition</div>
                    <div className='main-product-section-size-selector-dimensions'>46 x 66 in</div>
                    <div className='main-product-section-size-selector-price'>
                      <span className='reset-money' style={{padding: 0, display: 'inline'}}>$2825</span>
                    </div>
                  </label>
                </div>

                {/* Dropdown for mobile screens */}
                <div className="main-product-section-size-selectors-container-mobile-only" onClick={() => setDropdownOpen(!dropdownOpen)}>
                  <div className="dropdown-select-items-sizes">
                    <div className="selected-value">
                      <div className="main-product-section-size-selector-name">
                        {size.charAt(0).toUpperCase() + size.slice(1)}
                      </div>
                      <div className="main-product-section-size-selector-dimensions">
                        {sizesData[size].dimensions}
                      </div>
                      <div className="main-product-section-size-selector-price">
                        ${sizesData[size].price}
                      </div>
                    </div>
                    <div className={`arrow-sizes ${dropdownOpen ? "up" : "down"}`}></div>
                  </div>

                  {dropdownOpen && (
                    <div className="dropdown-list-sizes modern-list">
                      {Object.keys(sizesData).map((sizeOption) => (
                        <div
                          key={sizeOption}
                          className="dropdown-list-text-sizes"
                          onClick={() => handleSizeChange({ target: { value: sizeOption } })}
                        >
                          <div className="main-product-section-size-selector-name">
                            {sizeOption.charAt(0).toUpperCase() + sizeOption.slice(1)}
                          </div>
                          <div className="main-product-section-size-selector-dimensions">
                            {sizesData[sizeOption].dimensions}
                          </div>
                          <div className="main-product-section-size-selector-price">
                            ${sizesData[sizeOption].price}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className='main-product-frame-selector-container'>
                <div className='main-product-frame-selector-title'>
                  <span className='frame-selector-label'>Choose Your Frame</span>
                  <span className='frame-selector-price'>Free</span>
                  <span className='frame-selector-color'>{frameColorName}</span>
                </div>
                <div className='main-product-frame-selectors-container' onChange={handleFrameChange}>
                  <input id='frame-selector-Black' name='frame' type="radio" value={'Black'} className='frame-selector-input' 
                     defaultChecked/>
                  <label htmlFor="frame-selector-Black" className='frame-selector-swatch-label'>
                    <div className='frame-selector-swatch black' style={{backgroundColor: 'black'}}></div>
                  </label>

                  <input id='frame-selector-White' name='frame' type="radio" value={'White'} className='frame-selector-input'/>
                  <label htmlFor="frame-selector-White" className='frame-selector-swatch-label'>
                    <div className='frame-selector-swatch white' style={{backgroundColor: 'white'}}></div>
                  </label>

                  <input id='frame-selector-Wood' name='frame' type="radio" value={'Wood'} className='frame-selector-input'/>
                  <label htmlFor="frame-selector-Wood" className='frame-selector-swatch-label'>
                    <div className='frame-selector-swatch ' style={{backgroundImage: `url(${'https://images.pexels.com/photos/129728/pexels-photo-129728.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'})`}}></div>
                  </label>

                  <input id='frame-selector-NoFrame' name='frame' type="radio" value={'No Frame'} className='frame-selector-input'/>
                  <label htmlFor="frame-selector-NoFrame" className='frame-selector-swatch-label'>
                    <div className='frame-selector-swatch no-frame'></div>
                  </label>
                </div>
              </div>
              <div className='main-product-mount-selector-container'>
                <div className='main-product-mount-selector-title'>
                  <span className='mount-selector-label'>Choose Your Mount</span>
                  <span className='mount-selector-border'>{border}</span>
                </div>
                <div className='main-product-mount-selectors-container'>
                  <input defaultChecked={border === 'Border'} className='mount-selector-input ' value={'Border'} name='mount' 
                    id='mount-selector-border' type="radio" onChange={handleMountChange}/>
                  <label htmlFor="mount-selector-border" className='mount-selector'>
                    <div className='mount-selector-name'>Border</div>
                  </label>

                  <input className='mount-selector-input' value={'Full Bleed'} name='mount' id='mount-selector-full-bleed' 
                  type="radio" onChange={handleMountChange} defaultChecked={border === 'Full Bleed'}/>
                  <label htmlFor="mount-selector-full-bleed" className='mount-selector'>
                    <div className='mount-selector-name'>Full Bleed</div>
                  </label>
                </div>
              </div>
              <div className='main-product--add-to-cart-container'>
                <form className='add-to-cart' id='product_form' method='post' action="/cart/">
                  <input type="hidden" name="form_type" value="product" id="" />
                  <input type="hidden" name='utf8' value="" />
                  <select name="id" id="product_select" className='product-form__variants no-js' style={{display: 'none'}}>
                    <option value="">Small / Black / Border</option>
                  </select>
                  <button type='button' name='add' className='hos-button primary main-product-section-add-to-cart-button' onClick={handleClick}>
                    <span >Add to cart</span>
                  </button>
                  <input type="hidden" name='product-id' value={''} />
                </form>
                {showToast && (
                  <div class="toast ">
  
                    <div class="toast-content">
                      <i class="fas fa-solid fa-check check"></i>

                      <div class="message">
                        <span class="text text-1">Success</span> 
                        <br />
                        <span class="text text-2">Print added!</span>
                      </div>
                    </div>
                    <div class="progress "></div>
                  </div>
                )}
                <div className='save-button-container'>
                  <button className='save-button tw-border-black tw-font-sans ' >
                      <div className='save-button-text tw-min-w-[38px] tw-text-left'>
                        <span className='save-button-text-check group-aria-checked:tw-hidden '>Save</span>
                        <span className='save-button-text-checked group-aria-checked:tw-block'>Saved</span>
                      </div>
                  </button>
                </div>
                <div className='shipping-message-container'>
                  <p className='shipping-message'>Printed and framed in North America</p>
                </div>
              </div>
            </div>
          </div>
          <div className='product-information-section wrapper'>
            
            <div className='product-information-navigation-container'>
              <div className='product-information-navigation product-slick-initialized product-slick-slider'>
                <div className='product-slick-list'>
                  <div className='product-slick-track' style={{display: 'flex', width: 540}}>
                    <div 
                      className={`product-information-navigation-item ${activeTab === 'description' ? 'active' : ''}`}
                      onClick={() => changeTab('description')} 
                      style={{width: 180}}>Description</div>
                    <div 
                      className={`product-information-navigation-item ${activeTab === 'printing' ? 'active' : ''}`}
                      onClick={() => changeTab('printing')} 
                      style={{width: 280}}>Printing & Framing</div>
                    <div 
                      className={`product-information-navigation-item ${activeTab === 'shipping' ? 'active' : ''}`}
                      onClick={() => changeTab('shipping')}
                      style={{width: 280}}>Shipping & Returns</div>
                  </div>
                </div>
              </div>
            </div>
            <MobileTabs product={product}/>
            <div className='product-information-text-container product-slick-initialized product-slick-slider' id='info-container'>
              <div className='product-slick-list' style={{height: 'auto'}}>
                  {/* Description Tab */}
                    <div className={`product-information-text ${activeTab === 'description' ? 'active' : ''}`}
                      tabIndex={0}
                      style={{
                        zIndex: 999,
                        opacity: activeTab === 'description' ? 1 : 0,
                        display: activeTab === 'description' ? 'block' : 'none',
                      }}
                    >
                      <div className='product-information-text-contents js-accordion-body'>
                        <div className='product-information-column'>
                          <div className='product-information-text-title'>{product.artist}</div>
                          <div className='product-information-text-body'>
                          {product.title} was shot by <i>{product.artist}</i>, a talented self-taught photographer, retoucher, artist and founder of <i>Framed by Create</i> currently based in Philadelphia, PA. <i>{product.artist}</i> focuses on POV framing and 
                          thought provoking imagery. He shoots mostly travel, city and nature photography. 
                          </div>
                        </div>
                        <div className='product-information-column'>
                          <p><strong>Printing</strong></p>
                          <p>Each museum-quality Giclee Fine Art Print is part of our Premier collection and is available as an open edition for a limited time. Printed and framed locally in the USA on heavy matte, artist-grade, acid-free 300gsm photo paper using archival inks. All artworks are made-to-order specifically for you.</p>
                          <p><strong>Printing Size</strong></p>
                          <p>Print sizing refers to unframed, artwork-only orders. We include a 3" paper border so you can trim and frame the piece yourself.</p>
                          <p>
                            Small — Image Size: 8” x 12”; Paper Size: 14” x 18"<br />
                            Medium — Image Size: 13” x 19.5”; Paper Size: 19” x 25.5"<br />
                            Large — Image Size: 18” x 27”; Paper Size: 24” x 33”<br />
                            Giant — Image Size: 25.5” x 38”; Paper Size: 31” x 44”<br />
                            Collector — Image Size: 33.5” x 50”; Paper Size: 39.5” x 56"<br />
                            Exhibition — Image Size: 40” x 60”; Paper Size: 46” x 66"<br />
                          </p>
                          <p>
                            Small, Medium, and Large prints come with a 2.5” mat board border and are framed in our <span style={{ textDecoration: 'underline' }}>Classic Frames</span><br /><br />
                            Giant, Collector, and Exhibition prints come with a 2” fine art border and are framed in our premium <span style={{ textDecoration: 'underline' }}>Shadow Boxes</span>.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={`product-information-text ${activeTab === 'printing' ? 'active' : ''}`}
                        style={{ zIndex: 999, opacity: activeTab === 'printing' ? 1 : 0, display: activeTab === 'printing' ? 'block' : 'none' }}
                    >
                      <div className='product-information-text-contents js-accordion-body'>
                        <div className='product-information-column'>
                          <div className='product-information-text-title'> Our Framing Process
                          </div>
                          <p className=''>
                            Fine art photography should be protected and displayed which is why we offer complimentary custom 
                            framing on all of our pieces for customers here in the U.S.
                            <br />
                            <br />
                            Our premium hardwood frames are available in black, white and a beautiful natural wood. Each frame 
                            is handcrafted with a sheet of UV-blocking acrylic glass and comes with easy hanging hardware so your 
                            artwork is ready to hang as soon as it arrives.
                          </p>
                        </div>
                        <div className='product-information-column'>
                          <p>
                            <strong>Classic Frames</strong>
                          </p>
                          <p>
                          Our Small, Medium and Large prints come framed in a classic frame with a 2.5” mat board that runs around 
                          the edge of the photograph for an elevated look and feel.
                          </p>
                          <p>
                            <strong>Shadow Boxes</strong>
                          </p>
                          <p>
                          We offer premium shadow box frames on our Giant and Collector sized prints. Shadow boxes make a photograph more 
                          definitive by adding extra depth between the artwork and the acrylic glass. It is an absolutely stunning method 
                          of framing and the preferred choice of most photographers, galleries and designers.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Shipping & Returns Tab */}
                    <div className={`product-information-text ${activeTab === 'shipping' ? 'active' : ''}`}
                        style={{ zIndex: 999, opacity: activeTab === 'shipping' ? 1 : 0, display: activeTab === 'shipping' ? 'block' : 'none' }}
                    >
                    
                      <div className="product-information-text-contents js-accordion-body">
                        <div className="product-information-column">
                          <p><strong>Shipping</strong></p>
                          <p>Due to high demand, each piece can take 2 to 3 weeks to print, mount, frame, and ship. You'll receive a FedEx tracking number once it's shipped, so you can keep an eye on its journey.</p>
                        </div>
                        <div className="product-information-column">
                          <p><strong>Returns</strong></p>
                          <p>Each photograph is printed and framed to your specifications. This means we cannot accept returns or exchanges, nor can we make changes or cancellations after 24 hours.</p>
                          <p>If your artwork arrives damaged, please contact us for a repair or replacement!</p>
                        </div>
                      </div>
                    </div>
              </div>
            </div>
          </div>
          <section className='product-carousel-container wrapper'>
            <div className="product-carousel-header">
              <h2 className="product-carousel-header-title">SEE MORE {product.category}</h2>
              <a className="product-carousel-header-link" href="/shop/all-prints">
                See all
              </a>
            </div>
            <div className="carousel-arrow left-arrow">←</div>
            <div className="carousel-items-container">
              <CarouselItem frame={frame}/>
            </div>
            <div className="carousel-arrow right-arrow">→</div>


          </section>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Product