import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const NavigationContext = createContext(null);

export const useNavi = () => useContext(NavigationContext);


export const NavigationProvider = ({ children }) => {
  const [origin, setOrigin] = useState(sessionStorage.getItem('origin') || '/');
  const location = useLocation();

  useEffect(() => {
    // Exclude paths that shouldn't update the origin
    if (!['/login', '/register'].includes(location.pathname)) {
      setOrigin(location.pathname);
      sessionStorage.setItem('origin', location.pathname);  // Save to session storage
    }
  }, [location.pathname]);  // React to changes in path

  return (
    <NavigationContext.Provider value={{ origin }}>
      {children}
    </NavigationContext.Provider>
  );
};