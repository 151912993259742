import { Instagram } from '@mui/icons-material'
import React from 'react'
import "./styles/footer.css"

const Footer = () => {
  return (
    <div className='wrapper-footer'>
      <div className='container-footer'>
        <div className='left-footer-container'>
          <div className='footer-menus more'> 
            <h3>MORE</h3>
            <ul className='footer-ul'>
              <li className='footer-menus-item'>
                <a href="/policies/terms-of-service">Terms</a>
              </li>
              <li className='footer-menus-item'>
                <a href="">Privacy</a>
              </li>
              <li className='footer-menus-item'>
                <a href="">Return</a>
              </li>
            </ul>
          </div>
          <div className='footer-menus shop'> 
            <h3>SHOP</h3>
            <ul className='footer-ul'>
              <li className='footer-menus-item'>
                <a href="/shop/all-prints/">Prints</a>
              </li>
            </ul>
          </div>
          <div className='footer-menus support'> 
            <h3>SUPPORT</h3>
            <ul className='footer-ul'>
              <li className='footer-menus-item'>
                <a href="">Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <div className='right-footer-container'>
          <div className='footer-business-info'>
            Framed by .cREATE
            <br />
            Philadelphia, PA USA
          </div>
          <div className='footer-icons-container'>
            <div className='footer-info-icon'></div>
            <Instagram />
          </div>
        </div>
      </div>
      <div className='footer-tag'>2023, Create Company LLC. All Rights Reserved.</div>
    </div>
    
  )
}

export default Footer