import React from 'react'

const CarouselCard = ({item, frame}) => {
  console.log(frame)
  return (
    <div className="product-carousel-item">
      <div className="carousel-item-container  ">
        <a href={`/product/${item._id}`} className='carousel-item-image-holder loaded'>
          <div className='p-center lazyloaded'>
            {/* Frame Overlay */}
            {frame && <img className='carousel-item-frame-overlay' src={frame} alt="Frame" />}
            <span className='object-fit'>
              <img
                alt={item.title}
                className="carousel-item-image"
                src={item.fullBleed}
              />
            </span>
            
          </div>
        </a>
        <div className="carousel-item">
          <div>
            <div className="carousel-item-title-container">
              <a href="##" >
                <span aria-hidden="true" className="carousel-item-title" />
                {item.title}
              </a>
            </div>
            <p className="carousel-item-artist">{item.artist}</p>
          </div>
          <p className="carousel-item-options-container">6 sizes / $185</p>
        </div>
      </div>
    </div>
  )
}

export default CarouselCard