import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Announcement from '../components/Announcement'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../redux/userRedux'
import { useNavigate } from 'react-router-dom'
import MobileMenu from '../components/MobileMenu'
import CategoryMenu from '../components/CategoryMenu'

const Profile = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, isMember, isFetching, error } = useSelector(state => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
    setIsCategoriesOpen(false);
  };

  const handleCategoriesClick = () => {
    setIsCategoriesOpen(true);
  };

  const handleBack = () => {
    setIsCategoriesOpen(false);
  };


  if (isFetching) return <div>Loading...</div>;
  if (error) return <div>Error: Could not load user data. Please try again later.{error}</div>;

  const handleLogout = () => {
    dispatch(logout());
    navigate('/')
  };

  return (
    <div className='main-container'>
      <>
        <Navbar handleMenuToggle={handleMenuToggle} />
        {isOpen && (
          <MobileMenu
            isOpen={isOpen}
            handleMenuToggle={handleMenuToggle}
            handleCategoriesClick={handleCategoriesClick}
          />
        )}
        {isCategoriesOpen && <CategoryMenu handleBack={handleBack} />}
      </>
      <Announcement />
      <div>
        <button onClick={handleLogout}>SIGN OUT</button>
        <h1>Welcome, {currentUser?.name}</h1>
        {isMember && <p>You are a premium member!</p>}
      </div>
    </div>
  )
}

export default Profile