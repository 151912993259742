import React, { useEffect, useState } from 'react';
import './styles/slider.css';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@mui/icons-material';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  transition: all 1.5s ease;

  justify-content: center;
  align-items: center;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/print-shop-635e7.appspot.com/o/HERO%20IMAGE%2F215SKYLINE-BW.jpg?alt=media&token=797c3ffd-59a8-4015-a158-59253817cc1b');
  background-size: cover; 
  background-position: center; 
  position: relative; 
  transition: all 1.5s ease;
`;

const Slider = () => {
  return (
    <div className='containerSlider'>
      <Wrapper className='hero-banner'>
        <div className='infoContainer'>
          <h1 className='titleInfo'>WALLS THAT TALK</h1>
          <p className='descInfo'>Shop collection of fine art photography</p>
          <Link to={`/shop/all-prints/`}>
            <button className='buttonInfo'>SHOP NOW</button>
          </Link>
        </div>
      </Wrapper>
    </div>
  )
}

export default Slider