import { createContext, useState, useContext } from 'react';

const NavbarContext = createContext();
export const useNavbarContext = () => useContext(NavbarContext);

export const NavbarProvider = ({ children }) => {
    const [showNav, setShowNav] = useState(false);

    return (
        <NavbarContext.Provider value={{ showNav, setShowNav }}>
            {children}
        </NavbarContext.Provider>
    );
};
