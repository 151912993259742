import React, { useEffect, useState } from 'react'
import ProductItem from './ProductItem'
import axios from 'axios';

const Products = ({cat, filter, frame, orientation}) => {

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";
  
  useEffect(() => {
    const getProducts = async () => {
      try {
        let queryString = '';
        if (cat) {
          queryString += `?category=${cat}`;
        }
        if (orientation) {
          queryString += queryString ? 
            `&orientation=${orientation}` : `?orientation=${orientation}`;
        }
        const res = await axios.get(`${BASE_URL}/api/shop/all-prints${queryString}`);
        console.log(res.data)
        setProducts(res.data)
      } catch (err) {
        console.log(err)   
      } 
    }
    getProducts();
    
  },[cat, orientation]);
  

  useEffect(() => {
    const updatedFilteredProducts = products.filter((item) => 
      Object.entries(filter).every(([key, value]) => 
        value === "" || `${(item[key] && item[key].toLowerCase().includes(value.toLowerCase()))}`
      )
    );
    setFilteredProducts([...updatedFilteredProducts]);
  }, [products, filter, frame]);
  
  console.log(frame)
  return (
    <div className='product-list-page-items-container wrapper-full-width'>
      <div className='product-item-grid'>
        {cat ? 
          filteredProducts.map(item => (<ProductItem item={item} key={item._id} frame={frame} />)) 
          : 
          products.map(item => (<ProductItem item={item} key={item._id} frame={frame} /> ))}
      </div>
    </div>
  )
}

export default Products