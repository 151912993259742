import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Announcement from '../components/Announcement'
import './styles/cart.css'
import Footer from '../components/Footer'
import StripeCheckout from 'react-stripe-checkout';
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import {userRequest} from '../requestMethod';
import { removeProduct, updateTotal } from '../redux/cartRedux';
import { useNavi } from '../context/NavigationContext'
import MobileMenu from '../components/MobileMenu'
import CategoryMenu from '../components/CategoryMenu'


const KEY = process.env.REACT_APP_STRIPE_KEY;


const Cart = () => {

  const {cart, user} = useSelector(state => ({
    cart: state.cart,
    user: state.user
  }));
  const isMember = user.isMember;
  const [useMemberPricing, setUseMemberPricing] = useState(isMember);
  const [stripeToken, setStripeToken] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
    setIsCategoriesOpen(false);
  };

  const handleCategoriesClick = () => {
    setIsCategoriesOpen(true);
  };

  const handleBack = () => {
    setIsCategoriesOpen(false);
  };



  const onToken = (token) => {
    setStripeToken(token)
  };

  const clickRemove = (index) => {
    dispatch(
      removeProduct(index)
    )
  }

  useEffect(() => {
    dispatch(updateTotal());
  }, [dispatch, cart.products]);

  const handlePriceOptionChange = (event) => {
    if (event.target.id === 'member-price' && !isMember) {
      navigate('/register'); 
    }
  };

  const clickMember = () => {
    navigate('/register');
  }
  
  useEffect(() => {
    const makeRequest = async () => {
      try {
        const res = await userRequest.post (
          '/checkout/payment', {
            tokenId: stripeToken.id,
            amount: cart.total * 100
          }
        );
        navigate('/success',{
          state: {
            stripeData: res.data, 
            products: cart
          }});
      } catch (err) {}
    };
     stripeToken && makeRequest();
  }, [stripeToken, cart, cart.total, navigate]);

  return (
    <div className='cart-main-container'>
      <>
        <Navbar handleMenuToggle={handleMenuToggle} />
        {isOpen && (
          <MobileMenu
            isOpen={isOpen}
            handleMenuToggle={handleMenuToggle}
            handleCategoriesClick={handleCategoriesClick}
          />
        )}
        {isCategoriesOpen && <CategoryMenu handleBack={handleBack} />}
      </>
      <Announcement />
      <div className='background-overlay'></div>
      <div className='content-container'>
        <div className='cart-section'>
          <div className='cart-container'>
            <h1 className='cart-title'>My Cart</h1>
            <div className='data-cart-view'>
              <script></script>
              <form action="">
                <div className='cart-contents-container'>
                  <div className='cart-line-items-container'>
                    <div className='membership-savings-container'>
                      <div>
                        <div className='membership-savings-title'>
                          <span className='potential-savings' style={{textTransform: ""}}>SAVE ${cart.savings} on this order by becoming a member.</span>
                        </div>
                        <div className='membership-savings-subtitle'>In the blink of an eye. Access member pricing.</div>
                      </div>
                      <div>
                        <div className='membership-savings-button hos-button primary cart-member-btn membership-signup-button' onClick={clickMember}>Join Now</div>
                      </div>
                    </div>
                    <div className='cart-line-item-container'>
                      {cart.products.map((product, index)=> (
                        <div key={index} className='cart-line-item'>
                          <div className='cart-line-item-product-image'>
                          <a href={`/product/${product._id}`}>
                            {product.frameColor && <img src={product.frameColor} alt="Frame overlay" className="cart-frame-overlay" />}
                            <img src={product.border === 'Full Bleed' ? product.fullBleed : product.img} alt={product.title} />
                          </a>
                          </div>
                          <div className='cart-line-item-product-details'>
                            <div className='cart-line-item-product-title'>{product.title}</div>
                            <div className='cart-line-item-product-artist'>{product.artist}</div>
                            <div>
                              <div className='cart-line-item-price show-on-mobile-only'>
                              ${isMember ? product.memberPrice : product.price}
                              </div>
                              <div className='cart-line-item-price-type show-on-mobile-only'>{isMember ? "MEMBER PRICE" : "REGULAR PRICE"}</div>
                            </div>
                            <div className='cart-line-item-product-description'>
                              <span>
                                Print Size: {product.size}                             
                              </span>
                            </div>
                            <div className='cart-line-item-product-description'>
                              <span>
                                Frame: {product.frameColorName ? product.frameColorName : 'No Frame'}                            
                              </span>
                            </div>
                            <div className='cart-line-item-product-description'>
                              <span>
                                Mount: {product.border}
                              </span>
                            </div>
                            <div className='cart-line-item-quantity show-on-mobile-only'>Quantity: {product.quantity}</div>
                            <div className='cart-line-item-remove' onClick={() => clickRemove(index)}>
                              <a href="">Remove</a>
                            </div>
                          </div>
                          <div className='cart-line-item-quantity show-on-desktop-only'>{product.quantity}</div>
                          <div>
                            <div className='cart-line-item-price show-on-desktop-only'>
                              ${isMember ? product.memberPrice : product.price}
                            </div>
                            <div className='cart-line-item-price-type show-on-desktop-only'>{isMember ? "MEMBER PRICE" : "REGULAR PRICE"}</div>
                          </div>
                        </div>
                      ))}                       
                    </div>
                  </div>
                  <div className='cart-order-summary-container'>
                    <h3>Order Summary</h3>
                    <div className='order-summary-shipping-container'>
                      <div className='order-summary-shipping '>Shipping</div>
                      <div className='order-summary-cost'>Free (for a limited time)</div>
                    </div>
                    <div className='cart-order-summary-gift-promo-code-line'>
                      <div>Promo Code</div>
                      <div className='cart-order-summary-promo-code-line-value'>At Checkout</div>
                    </div>
                    <div className='cart-order-summary-subtotal-line'>
                      <div className='cart-order-summary-subtotal-line-label'>Subtotal</div>
                      <div className='cart-order-summary-subtotal-line-value'>${cart.total}</div>
                    </div>
                    <div className='membership-payment-options-container'>
                      {isMember ? (
                        <>
                         <label className='membership-payment-option-container checked' htmlFor="regular-price">
                            <input type="radio" id='regular-price' name="priceOption" disabled={isMember} checked={!isMember} onChange={handlePriceOptionChange} />
                            <span className='radio-selector'></span>
                            <span className='label-text'>Regular Price</span>
                            <span className='price-value'>${cart.products.length > 0 ? cart.regularTotal : 0}</span>
                          </label>

                          <label htmlFor="member-price" className='membership-payment-option-container cart-member-btn membership-signup-button '>
                            <input type="radio" id='member-price' name='priceOption' checked={isMember} onChange={handlePriceOptionChange} />
                            <span className='radio-selector'></span>
                            <span className='label-text'>Members</span>
                            <span className='price-value'>${cart.products.length > 0 ? cart.memberTotal : 0}</span>
                          </label>
                        </>
                      ): (
                        <>
                          <label className='membership-payment-option-container checked' htmlFor="regular-price">
                            <input type="radio" id='regular-price' name="priceOption" checked={!isMember} onChange={handlePriceOptionChange} />
                            <span className='radio-selector'></span>
                            <span className='label-text'>Regular Price</span>
                            <span className='price-value'>${cart.products.length > 0 ? cart.total : 0}</span>
                          </label>

                          <label htmlFor="member-price" className='membership-payment-option-container cart-member-btn membership-signup-button  '>
                            <input type="radio" id='member-price' name="priceOption" onChange={handlePriceOptionChange}  />
                            <span className='radio-selector'></span>
                            <span className='label-text'>Members save ${cart.savings}</span>
                            <span className='price-value'>${cart.products.length > 0 ? cart.total - cart.savings : 0}</span>
                          </label>
                        </>
                      )}
                    </div>
                    <div>Klarna?</div>
                    {stripeToken ? (
                      <span>Processing...Please wait.</span>) : (
                        <StripeCheckout 
                          name='company name' 
                          image=''
                          billingAddress
                          shippingAddress
                          description= {`Your total is $${cart.total}`}
                          amount={cart.total*100}
                          token={onToken}
                          stripeKey={KEY}
                        >
                          <input 
                            name='checkout'
                            value='Checkout'
                            className='cart-order-summary-checkout-button hos-button primary' 
                          />
                        </StripeCheckout>
                    )}
                    <div className='cart-order-summary-tax-message'>Taxes calculated at checkout</div>
                    <div className='cart-order-summary-continue-shopping'>
                      <a href="/shop/all-prints">Continue Shopping</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Cart