import React from 'react'
import './styles/mobile-menu.css'

const MobileMenu = ({ isOpen, handleMenuToggle, handleCategoriesClick }) => {
  if (!isOpen) return null;

  return (
    <div className="mobile-navigation-overlay">
      <div className='mobile-navigation-panel'>
        <ul className="mobile-navigation-links">
          <li><a href="/shop/all-prints/" onClick={handleMenuToggle}>SHOP ALL</a></li>
          {/* <li><a href="#about" onClick={handleMenuToggle}>NEW ARRIVALS</a></li> */}
          <li><a href="#" onClick={handleCategoriesClick}>CATEGORIES</a></li>
          <li><a href="/register" onClick={handleMenuToggle}>MEMBERSHIP</a></li>
        </ul>
      </div>
    </div>
  )
}

export default MobileMenu